import { Frame, Scroll } from "framer"

export function FontFeature(Component) {
    return (props) => {
        return (
            <Scroll direction="vertical">
                {props.data.map((item, index) => (
                    <Frame
                        key={index}
                        background={randomColor()}
                        style={{
                            fontFeatureSettings: "ss01",
                            marginBottom: "10px",
                        }}
                    >
                        {item}
                    </Frame>
                ))}
            </Scroll>
        )
    }
}
